import { useCallback, useMemo, useState } from "react";
import { useStores } from "core/context/stores";
import { GeolocationErrors } from "main/utils/errors";
import { usePosition } from "core/context/position";
import { useDirections } from "core/context/directions";
import { createPosition } from "main/utils/position";
import { analyticsHandler } from "core/events";
import { createAutocompleteHandler, retailTuneAutocompleteHandler } from "@retailtune/utils";
import { googleAutocompleteHandler } from "@retailtune/google-maps-utils";
export default function useAutocomplete(configuration, autocompleteType) {
    const { allStores } = useStores();
    const { updateCurrentPosition } = usePosition();
    const { setOrigin } = useDirections();
    const [autocompleteValue, setAutocompleteValue] = useState("");
    const countries = useMemo(() => {
        return Array.from(new Set(allStores.map(s => s.country.tagISO31661Alpha2)));
    }, [allStores]);
    const handleSearch = useCallback(createAutocompleteHandler(retailTuneAutocompleteHandler(configuration.api.retailtune, {
        language: configuration.language,
        countries,
    }), googleAutocompleteHandler()), [countries]);
    const handlePredictionClick = useCallback((prediction) => {
        if (!(prediction.latitude && prediction.longitude)) {
            return console.error(GeolocationErrors.BAD_PREDICTION);
        }
        const position = createPosition({
            latitude: prediction.latitude,
            longitude: prediction.longitude,
        });
        switch (autocompleteType) {
            case "geolocation": {
                // console.log(`type: '${autocompleteType}' - prediction: ${prediction}`);
                analyticsHandler.dispatch({
                    type: "free_search",
                    subtype: "geolocation",
                    payload: { search: prediction.description },
                });
                return updateCurrentPosition(position);
            }
            case "directions": {
                // console.log(`type: '${autocompleteType}' - prediction: ${prediction}`);
                analyticsHandler.dispatch({
                    type: "free_search",
                    subtype: "directions",
                    payload: { search: prediction.description },
                });
                return setOrigin(position);
            }
        }
    }, []);
    return { autocompleteValue, setAutocompleteValue, handleSearch, handlePredictionClick };
}
