var OpeningsErrors;
(function (OpeningsErrors) {
    OpeningsErrors["CASE_NOT_DEFINED"] = "Opening case is not defined";
    OpeningsErrors["BAD_PARAMS"] = "Bad parameters provided to the opening text function";
    OpeningsErrors["BAD_HOURS_REPRESENTATION"] = "Bad string representation for opening hours";
})(OpeningsErrors || (OpeningsErrors = {}));
var Openings;
(function (Openings) {
    Openings[Openings["NO_OPENING_DETAILS"] = 0] = "NO_OPENING_DETAILS";
    Openings[Openings["TODAY_CLOSED"] = 1] = "TODAY_CLOSED";
    Openings[Openings["CLOSED_OPENS_AT"] = 2] = "CLOSED_OPENS_AT";
    Openings[Openings["CLOSED_REOPENS"] = 3] = "CLOSED_REOPENS";
    Openings[Openings["CLOSED_REOPENS_AT"] = 4] = "CLOSED_REOPENS_AT";
    Openings[Openings["OPEN_UNTIL"] = 5] = "OPEN_UNTIL";
    Openings[Openings["OPEN_UNTIL_REOPENS_AT"] = 6] = "OPEN_UNTIL_REOPENS_AT";
})(Openings || (Openings = {}));
const timeTextCache = {};
class Time {
    constructor(hour, minutes) {
        this.h = hour % 24;
        this.m = minutes % 60;
    }
    get hour() {
        return this.h;
    }
    get minutes() {
        return this.m;
    }
    addHours(h1) {
        this.h = (this.h + h1) % 24;
    }
    addMinutes(m1) {
        let minutes = this.m + m1;
        if (minutes >= 60) {
            const extraHours = Math.floor(minutes / 60);
            minutes = minutes % 60;
            this.addHours(extraHours);
        }
        this.m = minutes;
    }
    compare(other) {
        if (this.h > other.hour)
            return 1;
        if (this.h < other.hour)
            return -1;
        if (this.m > other.minutes)
            return 1;
        if (this.m < other.minutes)
            return -1;
        return 0;
    }
    isContainedIn(time1, time2) {
        return this.compare(time1) > 0 && this.compare(time2) < 0;
    }
    static strToTime(str, delimiter) {
        const [h, m] = str.split(delimiter).map(s => s.trim());
        return new Time(parseInt(h), parseInt(m));
    }
    toString() {
        const h = this.h < 10 ? `0${this.h}` : this.h;
        const m = this.m < 10 ? `0${this.m}` : this.m;
        return `${h}:${m}`;
    }
}
function createText(id, storeCode, translations, details) {
    function _createText() {
        let text = "";
        switch (id) {
            case Openings.NO_OPENING_DETAILS: {
                // no informations about the opening hours are provided
                return text;
            }
            case Openings.TODAY_CLOSED: {
                // store is closed indefinitely
                text = translations.todayClosed;
                return text;
            }
            case Openings.CLOSED_OPENS_AT: {
                // store is closed now. Opens today in the moring
                if (details && Array.isArray(details) && details.length > 0) {
                    text = translations.closedOpensAt;
                    text = text.replace("__time", details[0]);
                    return text;
                }
                break;
            }
            case Openings.CLOSED_REOPENS_AT: {
                // store is closed now. Reopens today at a given hour
                if (details && Array.isArray(details) && details.length > 0) {
                    text = translations.closedReopensAt;
                    text = text.replace("__time", details[0]);
                    return text;
                }
                break;
            }
            case Openings.CLOSED_REOPENS: {
                // store is closed. Will reopen the next days (info provided)
                if (details && !Array.isArray(details)) {
                    const nextOpeningTime = details.hours.split("-")[0];
                    text = translations.closedReopens;
                    text = text.replace("__day", details.dayName);
                    text = text.replace("__time", nextOpeningTime);
                    return text;
                }
                break;
            }
            case Openings.OPEN_UNTIL: {
                // store is open until a given hour
                if (details && Array.isArray(details) && details.length > 0) {
                    text = translations.openUntil;
                    text = text.replace("__time", details[0]);
                    return text;
                }
                break;
            }
            case Openings.OPEN_UNTIL_REOPENS_AT: {
                // store is open until a given hour and reopens later during the day
                if (details && Array.isArray(details) && details.length > 1) {
                    text = translations.openUntilReopensAt;
                    text = text.replace("__time1", details[0]);
                    text = text.replace("__time2", details[1]);
                    return text;
                }
                break;
            }
            default: {
                throw new Error(OpeningsErrors.CASE_NOT_DEFINED);
            }
        }
        throw new Error(OpeningsErrors.BAD_PARAMS);
    }
    let text = "";
    try {
        text = _createText();
    }
    catch (e) {
        console.error(`Store code: ${storeCode}: ${e}`);
    }
    finally {
        timeTextCache[storeCode] = text;
    }
    return text;
}
// Returns an array of 7 objects which contain informations on the opening hours
// of the corresponding day of the week
function getWeekOpeningHours(language, openingHours) {
    const date = new Date();
    const firstDayOfTheWeekDate = new Date();
    firstDayOfTheWeekDate.setDate(date.getDate() + 1 - date.getDay());
    const array = new Array(7);
    for (let i = 0; i < 7; ++i) {
        // must be a new object every iteration, else next line is buggy
        const day = new Date(firstDayOfTheWeekDate);
        day.setDate(day.getDate() + i);
        const isToday = day.getDate() === date.getDate();
        const dayName = day.toLocaleString(language, { weekday: "long" });
        const hours = openingHours[i];
        array[i] = { hours, isToday, dayName };
    }
    return array;
}
function findNextReopening(storeCode, weeklyOpeningDetails, translations) {
    const isOpen = (od) => od.hours !== "x" && od.hours !== "" && od.hours;
    const todayIndex = weeklyOpeningDetails.findIndex(d => d.isToday);
    // searching through the remaining days of this week
    const rHalf = weeklyOpeningDetails.slice(todayIndex + 1);
    let nextOpening = rHalf.find(d => isOpen(d));
    if (nextOpening)
        return createText(Openings.CLOSED_REOPENS, storeCode, translations, nextOpening);
    // searching through the first days of the next week until the same day of today
    const lHalf = weeklyOpeningDetails.slice(0, todayIndex);
    nextOpening = lHalf.find(d => isOpen(d));
    if (nextOpening)
        return createText(Openings.CLOSED_REOPENS, storeCode, translations, nextOpening);
    return createText(Openings.TODAY_CLOSED, storeCode, translations);
}
function createOpeningTimeFromServerTime({ serverTime, currentHour, currentMinutes, translations, storeInfo: { storeCode, openingHours, weeklyOpeningDetails, timeOffset }, }) {
    const cachedValue = timeTextCache[storeCode];
    if (cachedValue)
        return cachedValue;
    const d = new Date(serverTime * 1000).getDay();
    // Sunday openings use index 7 in Taffy while getDay() returns 0 for sundays
    const dayIndex = d == 0 ? 6 : d - 1;
    let open1, close1, open2, close2;
    const opening = openingHours[dayIndex];
    if (opening === "" || !opening) {
        return createText(Openings.NO_OPENING_DETAILS, storeCode, translations);
    }
    if (opening === "x") {
        return findNextReopening(storeCode, weeklyOpeningDetails, translations);
    }
    const [opening1, opening2] = opening.split(",");
    [open1, close1] = opening1.split("-").map(str => Time.strToTime(str, ":"));
    if (!(open1 && close1))
        throw new Error(OpeningsErrors.BAD_HOURS_REPRESENTATION);
    [open2, close2] = opening2
        ? opening2.split("-").map(str => Time.strToTime(str, ":"))
        : [null, null];
    if (!(open1 || close1 || open2 || close2)) {
        return createText(Openings.TODAY_CLOSED, storeCode, translations);
    }
    const today12Am = new Time(0, 0);
    const currentTime = new Time(currentHour, currentMinutes);
    currentTime.addMinutes(Math.round(timeOffset / 60));
    if (currentTime.isContainedIn(today12Am, open1)) {
        return createText(Openings.CLOSED_OPENS_AT, storeCode, translations, [open1.toString()]);
    }
    if (open2 && currentTime.isContainedIn(open1, close1)) {
        return createText(Openings.OPEN_UNTIL_REOPENS_AT, storeCode, translations, [
            close1.toString(),
            open2.toString(),
        ]);
    }
    if (currentTime.isContainedIn(open1, close1)) {
        return createText(Openings.OPEN_UNTIL, storeCode, translations, [close1.toString()]);
    }
    if (open2 && currentTime.isContainedIn(close1, open2)) {
        return createText(Openings.CLOSED_REOPENS_AT, storeCode, translations, [open2.toString()]);
    }
    if (open2 && close2 && currentTime.isContainedIn(open2, close2)) {
        return createText(Openings.OPEN_UNTIL, storeCode, translations, [close2.toString()]);
    }
    return findNextReopening(storeCode, weeklyOpeningDetails, translations);
}
function getOpeningText(language, store, serverTime, openingTimes) {
    return createOpeningTimeFromServerTime({
        serverTime: serverTime.serverTime,
        currentHour: serverTime.hours,
        currentMinutes: serverTime.minutes,
        translations: {
            todayClosed: openingTimes.closed.today,
            closedOpensAt: openingTimes.closed.opensAt,
            closedReopens: openingTimes.closed.reopens,
            closedReopensAt: openingTimes.closed.reopensAt,
            openUntil: openingTimes.open.until,
            openUntilReopensAt: openingTimes.open.untilReopensAt,
        },
        storeInfo: {
            openingHours: store.openingHours,
            storeCode: store.storeCode,
            timeOffset: store.hourOffset,
            weeklyOpeningDetails: getWeekOpeningHours(language, store.openingHours),
        },
    });
}
export function createOpeningTextCache({ language, stores, serverTime, openingTimes, }) {
    const cache = new Map();
    if (!(serverTime && openingTimes)) {
        return cache;
    }
    else {
        for (let i = 0; i < stores.length; ++i) {
            cache.set(stores[i].storeCode, getOpeningText(language, stores[i], serverTime, openingTimes));
        }
        return cache;
    }
}
