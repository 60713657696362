import classNames from "classnames";
import { loadRepresentation } from "core/parser";
import React, { useState } from "react";
import "./ListSwitch.css";
function Component({ children, configuration, ...rest }) {
    // const r = useRef<HTMLDivElement>(null);
    const [isListVisible, setListVisible] = useState(false);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "rt-list-switch" },
            React.createElement("button", { className: classNames("rt-switch-button", { selected: isListVisible }), onClick: () => setListVisible(true) }, configuration.translations.controls.switch.list),
            React.createElement("button", { className: classNames("rt-switch-button", { selected: !isListVisible }), onClick: () => setListVisible(false) }, configuration.translations.controls.switch.map)),
        React.createElement("div", { 
            // ref={r}
            // className="rt-stores-area"
            className: classNames("rt-stores-area", {
                "rt-stores-area--list-only": isListVisible,
            }) }, children(rest))));
}
export const dynamicComponent = {
    Component,
    checkExpectedProps: (props) => { },
    children: (repr, config) => (props) => loadRepresentation(repr, config, props),
};
