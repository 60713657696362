import React, { useMemo, useState } from "react";
import classNames from "classnames";
import { useStores } from "core/context/stores";
function Component({ configuration }) {
    const defaultValue = configuration.translations.header.filters.allTypologies;
    const { allStores, setFilter } = useStores();
    const storeTypes = useMemo(() => [...new Set(allStores.flatMap((s) => s.storeTypes))], [allStores]);
    const [selected, setSelected] = useState(defaultValue);
    const [isOpen, setOpen] = useState(false);
    function hanldeFilterClick(storeType) {
        setOpen(false);
        setSelected(storeType);
        const updatedStoreTypes = storeType === defaultValue ? storeTypes : [storeType];
        setFilter((prev) => ({
            ...prev,
            storeTypes: updatedStoreTypes,
        }));
    }
    return (React.createElement("div", { className: classNames("rt-filters-dropdown", {
            "rt-filters-dropdown--open": isOpen,
        }) },
        React.createElement("div", { className: "rt-filters-dropdown__selected-item", onClick: () => setOpen((prev) => !prev) }, selected),
        React.createElement("ul", { className: "rt-filters-dropdown__items" },
            React.createElement("li", { key: "__default-type", className: "rt-filters-dropdown__item", onClick: () => hanldeFilterClick(defaultValue) }, defaultValue),
            storeTypes.map((t) => (React.createElement("li", { key: t, className: "rt-filters-dropdown__item", onClick: () => hanldeFilterClick(t) }, t))))));
}
export const dynamicComponent = {
    Component,
    checkExpectedProps: (props) => { },
};
