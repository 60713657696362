import { createContext, useContext } from "react";
export const UserConsentContext = createContext({
    positionConsent: false,
    setPositionConsent: () => { },
    isPositionAlertVisible: false,
    setPositionAlertVisbile: () => { },
    storageConsent: false,
    setStorageConsent: () => { },
    isStorageAlertVisible: false,
    setStorageAlertVisbile: () => { },
});
UserConsentContext.displayName = "UserConsentContext";
export const useUserConsent = () => useContext(UserConsentContext);
