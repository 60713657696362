import { PositionType } from "types/position";
import { GeolocationErrors } from "./errors";
import { ExpirationTime } from "./time-expiration";
import { ExpirationType } from "main/types/time-expiration";
import { getPositionData, LOCAL_STORAGE_USER_POSIITON, saveExpirable, } from "./local-storage";
import { toast } from "react-toastify";
import { analyticsHandler } from "core/events";
// --- CONSTANTS
export const EARTH_RADIUS = 6371; // Km
export const ACCURACY_THRESHOLD = 20000; // meters
export function createPosition({ latitude, longitude, accuracy, src, }) {
    return {
        latitude,
        longitude,
        accuracy: accuracy !== null && accuracy !== void 0 ? accuracy : 1,
        src: src !== null && src !== void 0 ? src : PositionType.FIXED,
    };
}
// export function getDefaultPosition() {
//     return createPosition({
//         latitude: 45.101,
//         longitude: 11.3939,
//         src: PositionType.DEFAULT,
//     });
// }
export function positionToLatLng(position) {
    return { lat: position.latitude, lng: position.longitude };
}
// --- POSITION FETCH & GEOLOCATION UTILITIES
const degToRad = (deg) => deg * 0.0174533;
/**
 * returns the distance in km between the points (lat1, lon1), (lat2, lon2)
 */
export function getDistanceFn(lat1, lon1) {
    const r1 = degToRad(lat1), r3 = degToRad(lon1);
    return function (lat2, lon2) {
        //source for the formula and for earth's radius: http://janmatuschek.de/LatitudeLongitudeBoundingCoordinates
        //dist = arccos(sin(lat1) · sin(lat2) + cos(lat1) · cos(lat2) · cos(lon1 - lon2)) · R
        const r2 = degToRad(lat2), r4 = degToRad(lon2);
        return (Math.acos(Math.sin(r1) * Math.sin(r2) +
            Math.cos(r1) * Math.cos(r2) * Math.cos(r3 - r4)) * EARTH_RADIUS);
    };
}
export async function html5Geolocation(accuracyThreshold) {
    if (!navigator.geolocation)
        throw new Error(GeolocationErrors.GEOLOCATION_DISABLED);
    return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition((position) => position.coords.accuracy > accuracyThreshold
            ? reject(GeolocationErrors.ACCURACY_NOT_ENOUGH)
            : resolve(createPosition({
                latitude: position.coords.latitude,
                longitude: position.coords.longitude,
                accuracy: position.coords.accuracy,
            })), (e) => reject(e), {
            enableHighAccuracy: true,
            timeout: 3000,
        });
    });
}
export function getGeolocationFn(defaultPosition, errorMessage, ...geolocationMethods) {
    function saveUserPosition(position) {
        const positionData = {
            position,
            expiration: new ExpirationTime(15, ExpirationType.MINUTES).timeInMillis(),
        };
        saveExpirable(LOCAL_STORAGE_USER_POSIITON, positionData);
    }
    return async function geolocateUser() {
        // .. fetching position from local storage
        try {
            const position = getPositionData(LOCAL_STORAGE_USER_POSIITON);
            if (!position)
                throw new Error();
            switch (position.src) {
                case PositionType.HTML5: {
                    analyticsHandler.dispatch({
                        type: "geolocation_html5",
                        subtype: "cache"
                    });
                    break;
                }
                case PositionType.IP: {
                    analyticsHandler.dispatch({
                        type: "geolocation_ip",
                        subtype: "cache"
                    });
                    break;
                }
                case PositionType.DEFAULT: {
                    analyticsHandler.dispatch({
                        type: "geolocation_default_position",
                        subtype: "cache"
                    });
                    break;
                }
            }
            return position;
        }
        catch (e) { }
        // .. fetching based on the geolocation methods provided
        for (let i = 0; i < geolocationMethods.length; ++i) {
            try {
                return await geolocationMethods[i](saveUserPosition);
            }
            catch (_) { }
        }
        // .. if no position can be fetched, default position is returned
        saveUserPosition(defaultPosition);
        toast(errorMessage);
        analyticsHandler.dispatch({
            type: "geolocation_default_position",
            subtype: "success"
        });
        return defaultPosition;
    };
}
export function getGeolocationMethodHTML5(accuracyThreshold) {
    const geolocationMethodHTML5 = async (saveUserPosition) => {
        try {
            const position = await html5Geolocation(accuracyThreshold);
            saveUserPosition(position);
            analyticsHandler.dispatch({
                type: "geolocation_html5",
                subtype: "success"
            });
            return position;
        }
        catch (e) {
            analyticsHandler.dispatch({
                type: "geolocation_html5",
                subtype: "error"
            });
            throw e;
        }
    };
    return geolocationMethodHTML5;
}
