import React, { useEffect } from "react";
import classNames from "classnames";
import { TRAVEL_MODE } from "types/directions";
import useAutocomplete from "main/hooks/autocomplete";
import { useDirections } from "core/context/directions";
import { Autocomplete } from "@retailtune/react-ui-core";
function Component({ configuration }) {
    var _a, _b;
    const { directions, resetDirections, setTravelMode } = useDirections();
    const { autocompleteValue, setAutocompleteValue, handleSearch, handlePredictionClick } = useAutocomplete(configuration, "directions");
    useEffect(() => {
        if (!directions.store)
            setAutocompleteValue("");
    }, [directions.store]);
    return (React.createElement("div", { className: classNames("rt-directions-pane", {
            "rt-directions-pane--visible": directions.store,
        }) },
        React.createElement("img", { className: "rt-directions-pane__close-button", src: `${configuration.hostname}data/assets/close.svg`, onClick: resetDirections }),
        React.createElement("ul", { className: "rt-directions-pane__travel-modes" },
            React.createElement("li", { className: classNames("", {
                    "rt-directions-pane__travel-mode--selected": directions.travelMode === TRAVEL_MODE.WALKING,
                }) },
                React.createElement("img", { className: "rt-directions-pane__travel-mode", onClick: () => setTravelMode(TRAVEL_MODE.WALKING), src: `${configuration.hostname}data/assets/walk.svg`, alt: "walking" })),
            React.createElement("li", { className: classNames("", {
                    "rt-directions-pane__travel-mode--selected": directions.travelMode === TRAVEL_MODE.DRIVING,
                }) },
                React.createElement("img", { className: "rt-directions-pane__travel-mode", onClick: () => setTravelMode(TRAVEL_MODE.DRIVING), src: `${configuration.hostname}data/assets/car.svg`, alt: "driving" })),
            React.createElement("li", { className: classNames("", {
                    "rt-directions-pane__travel-mode--selected": directions.travelMode === TRAVEL_MODE.PUBLIC_TRANSPORT,
                }) },
                React.createElement("img", { className: "rt-directions-pane__travel-mode", onClick: () => setTravelMode(TRAVEL_MODE.PUBLIC_TRANSPORT), src: `${configuration.hostname}data/assets/train.svg`, alt: "bus" }))),
        React.createElement("div", { className: "rt-directions-pane__travel" },
            React.createElement(Autocomplete, { value: autocompleteValue, setValue: setAutocompleteValue, searchHandler: handleSearch, predictionClickHandler: handlePredictionClick, zeroResultsMessage: configuration.translations.homeTranslations.autocomplete.zeroResultsMessage, placeholder: configuration.translations.homeTranslations.autocomplete.placeholder }),
            React.createElement("input", { disabled: true, className: "rt-directions-pane__travel-destination", placeholder: (_a = directions.store) === null || _a === void 0 ? void 0 : _a.address1 })),
        React.createElement("p", { className: "rt-directions-pane__instructions-start" }, configuration.translations.directionsPane.yourAddress),
        React.createElement("ol", { className: "rt-directions-pane__instructions" }, directions.directionsInfo &&
            directions.directionsInfo.steps.map((s, i) => (React.createElement("li", { key: i, className: "rt-directions-pane__instruction", dangerouslySetInnerHTML: {
                    __html: s,
                } })))),
        React.createElement("p", { className: "rt-directions-pane__instructions-end" }, (_b = directions.store) === null || _b === void 0 ? void 0 : _b.address1)));
}
export const dynamicComponent = {
    Component,
    checkExpectedProps: (props) => { },
};
