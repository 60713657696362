// mixin for the version of the autocomplete with the clear button
import { Autocomplete } from "@retailtune/react-ui-core";
import useAutocomplete from "main/hooks/autocomplete";
import React from "react";
function Component({ configuration, autocompleteType }) {
    const { autocompleteValue, setAutocompleteValue, handleSearch, handlePredictionClick } = useAutocomplete(configuration, autocompleteType);
    return (React.createElement(Autocomplete, { value: autocompleteValue, setValue: setAutocompleteValue, searchHandler: handleSearch, predictionClickHandler: handlePredictionClick, zeroResultsMessage: configuration.translations.homeTranslations.autocomplete.zeroResultsMessage, placeholder: configuration.translations.homeTranslations.autocomplete.placeholder, clearIcon: {
            path: configuration.autocomplete.clearIconPath,
            position: "left",
        }, searchIcon: {
            path: configuration.autocomplete.searchIconPath,
            position: "right",
        } }));
}
export const dynamicComponent = {
    Component,
    checkExpectedProps: props => { },
};
