import React, { memo } from "react";
function StoreCard({ store }) {
    return (React.createElement("div", { className: "store" },
        React.createElement("p", null,
            store.name,
            " "),
        React.createElement("p", null,
            store.address1,
            " - ",
            store.city)));
}
const Component = memo(StoreCard);
export const dynamicComponent = {
    Component,
    checkExpectedProps: (props) => { },
};
// interface ComponentProps extends PropsType {
//     store: Store;
//     setDirectionsStore: (store: Store) => void;
//     openingTimesCache: Map<string, string>;
//     configuration: StoreCardVariant1Configuration;
// }
// function Component({ store, setDirectionsStore, openingTimesCache, configuration }: ComponentProps) {
//     // const openingText = useMemo(() => getOpeningText(store, configuration), []);
//     function handleStoreCardClick() {
//         storeCardClickHandler.dispatch({
//             activeStore: store.storeCode,
//         });
//     }
//     function handleStoreInfoRequest(e: React.MouseEvent<HTMLButtonElement>) {
//         e.stopPropagation();
//         window.open(store.storeLink, "_blank");
//     }
//     function handleDrivingDirectionsRequest(e: React.MouseEvent<HTMLButtonElement>) {
//         e.stopPropagation();
//         setDirectionsStore(store);
//     }
//     return (
//         <li className="rt-store" onClick={handleStoreCardClick}>
//             <section className="rt-store__info">
//                 <h3 className="rt-store__info-name">{store.name}</h3>
//                 <p className="rt-store__info-address">{store.address1}</p>
//                 <p className="rt-store__city">
//                     {store.postalCode}, {store.city}
//                 </p>
//                 {store.phone && (
//                     <a className="rt-store__info-phone" href={`tel:${store.phone}`}>
//                         {store.phone}
//                     </a>
//                 )}
//                 {store.whastapp && (
//                     <a className="rt-store__info-whatsapp" href={`wa.me/${store.whastapp}`}>
//                         {configuration.translations.storeCard.whatsapp}
//                     </a>
//                 )}
//                 {openingTimesCache.has(store.storeCode) && (
//                     <p className="rt-store__info-openings">{openingTimesCache.get(store.storeCode)}</p>
//                 )}
//             </section>
//             <div className="rt-store__buttons">
//                 <button className="rt-store__button-details" onClick={handleStoreInfoRequest}>
//                     {configuration.translations.storeCard.info}
//                 </button>
//                 <button className="rt-store__button-directions" onClick={handleDrivingDirectionsRequest}>
//                     {configuration.translations.storeCard.directions}
//                 </button>
//             </div>
//         </li>
//     );
// }
// export const dynamicComponent: DynamicComponent<ComponentProps> = {
//     Component,
//     checkExpectedProps: (props) => {
//         const componentName = "StoreCard (variant 1)";
//         if (!props) throw new Error(missingProps(componentName));
//         if (!props.store) throw new Error(missingMandatoryProp(componentName, "store"));
//         if (!props.setDirectionsStore) throw new Error(missingMandatoryProp(componentName, "setDirectionsStore"));
//         if (!props.openingTimesCache) throw new Error(missingMandatoryProp(componentName, "openingTimesCache"));
//         if (!props.configuration) throw new Error(missingMandatoryProp(componentName, "configuration"));
//     },
//     filterProps: (props) => {
//         if (!props) throw new Error(missingProps("StoreCard (variant 1)"));
//         const neededProps = ["store", "setDirectionsStore", "openingTimesCache", "configuration"];
//         return Object.fromEntries(Object.entries(props).filter(([key, _]) => neededProps.includes(key)));
//     },
//     transformConfiguration: (config) => {
//         const storeCardConfig: StoreCardVariant1Configuration = {
//             time: {
//                 serverTime: config.time.serverTime,
//                 hours: config.time.hours,
//                 minutes: config.time.minutes,
//             },
//             translations: {
//                 openingTimes: config.translations.openingTimes,
//                 storeCard: config.translations.list.storeCard,
//             },
//         };
//         return storeCardConfig;
//     },
// };
