import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function Component({ configuration }) {
    return (React.createElement(ToastContainer, { autoClose: configuration.toastMessages.autoClose, position: configuration.toastMessages.position }));
}
export const dynamicComponent = {
    Component,
    checkExpectedProps: (props) => { },
};
