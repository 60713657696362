import { PositionContext } from "core/context/position";
import { useUserConsent } from "core/context/userConsent";
import { getGeolocationFn, getGeolocationMethodHTML5 } from "main/utils/position";
import React, { useCallback, useEffect, useMemo, useState } from "react";
function Component({ configuration, children, defaultPosition, userPosition, currentPosition }) {
    const { positionConsent, setPositionAlertVisbile } = useUserConsent();
    const [position, setPosition] = useState({
        defaultPosition,
        userPosition,
        currentPosition,
    });
    useEffect(() => {
        if (positionConsent) {
            geolocateUser();
        }
    }, [positionConsent]);
    function updateCurrentPosition(currentPosition) {
        setPosition((pos) => ({ ...pos, currentPosition }));
    }
    const findUserPosition = useCallback(getGeolocationFn(defaultPosition, configuration.translations.warnings.geolocation.cannotGeolocateUser, getGeolocationMethodHTML5(configuration.geolocation.accuracyThreshold)), []);
    const geolocateUser = useCallback(async () => {
        if (positionConsent) {
            const userPosition = await findUserPosition();
            setPosition((prev) => ({ ...prev, userPosition, currentPosition: userPosition }));
        }
        else {
            setPositionAlertVisbile(true);
        }
    }, [positionConsent]);
    const contextValue = useMemo(() => ({
        ...position,
        geolocateUser,
        updateCurrentPosition,
    }), [position, geolocateUser]);
    return React.createElement(PositionContext.Provider, { value: contextValue }, children);
}
export const dynamicComponent = {
    Component,
    checkExpectedProps: (props) => {
    },
};
