// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rt-cookiebar {
  position: fixed;
  display: flex;
  bottom: 0;
  width: 100%;
  background-color: white;
  padding: 10px;
}

.rt-cookiebar__accept-button {
  margin-left: auto;
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/components/dynamic/cookie-bar/CookieBar.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,aAAa;EACb,SAAS;EACT,WAAW;EACX,uBAAuB;EACvB,aAAa;AACf;;AAEA;EACE,iBAAiB;EACjB,eAAe;AACjB","sourcesContent":[".rt-cookiebar {\n  position: fixed;\n  display: flex;\n  bottom: 0;\n  width: 100%;\n  background-color: white;\n  padding: 10px;\n}\n\n.rt-cookiebar__accept-button {\n  margin-left: auto;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
