import { TRAVEL_MODE } from "main/types/directions";
import { createPosition } from "main/utils/position";
import { createContext, useContext } from "react";
export const DirectionsContext = createContext({
    directions: {
        userPosition: createPosition({ latitude: 44.101, longitude: 10.101 }),
        origin: createPosition({ latitude: 44.101, longitude: 10.101 }),
        store: undefined,
        directionsInfo: undefined,
        travelMode: TRAVEL_MODE.DRIVING,
    },
    resetDirections: () => { },
    setOrigin: (origin) => { },
    setStore: (store) => { },
    setDirectionsInfo: (directionsInfo) => { },
    setTravelMode: (travelMode) => { },
});
DirectionsContext.displayName = "DirectionsContext";
export const useDirections = () => useContext(DirectionsContext);
