import { useUserConsent } from "core/context/userConsent";
import React from "react";
import "./CookieBar.css";
function Component({ 
// translations: { disclaimer, buttonText, cookiePolicyLink, cookiePolicyText },
// setUserConsent,
configuration, }) {
    const { isStorageAlertVisible, setStorageConsent } = useUserConsent();
    return (React.createElement(React.Fragment, null, isStorageAlertVisible && (React.createElement("div", { className: "rt-cookiebar" },
        React.createElement("p", { className: "rt-cookiebar__disclaimer" },
            configuration.translations.cookieBar.disclaimer,
            React.createElement("a", { className: "rt-cookiebar__policy-link", target: "_blank", href: configuration.translations.cookieBar.cookiePolicyLink }, configuration.translations.cookieBar.cookiePolicyText)),
        React.createElement("button", { className: "rt-cookiebar__accept-button", type: "button", onClick: () => setStorageConsent(true) }, configuration.translations.cookieBar.buttonText)))));
}
export const dynamicComponent = {
    Component,
    checkExpectedProps: (props) => { },
};
