import React from "react";
import { useStores } from "core/context/stores";
function Component({ configuration }) {
    const { visibleStores } = useStores();
    return (React.createElement("p", { className: "rt-stores-found" },
        visibleStores.length,
        " ",
        visibleStores.length === 1
            ? configuration.translations.list.storeFound
            : configuration.translations.list.storesFound));
}
export const dynamicComponent = {
    Component,
    checkExpectedProps: (props) => { },
};
