// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.rt-accordion-menu {
    position: relative;
    max-width: 200px;
}

.rt-accordion-menu__main {
    padding: 10px 20px;
}

.rt-accordion-menu__items {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0;
    opacity: 0;
    visibility: hidden;
    transition: all .3s linear;
}

.rt-accordion-menu__items * {
    display: none;
}

.rt-accordion-menu__items--visible {
    opacity: 1;
    visibility: visible;
}

.rt-accordion-menu__items--visible * {
    display: initial;
}`, "",{"version":3,"sources":["webpack://./src/components/accordion/Menu.css"],"names":[],"mappings":";AACA;IACI,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,eAAe;IACf,gBAAgB;IAChB,SAAS;IACT,UAAU;IACV,UAAU;IACV,kBAAkB;IAClB,0BAA0B;AAC9B;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,UAAU;IACV,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":["\n.rt-accordion-menu {\n    position: relative;\n    max-width: 200px;\n}\n\n.rt-accordion-menu__main {\n    padding: 10px 20px;\n}\n\n.rt-accordion-menu__items {\n    display: flex;\n    flex-wrap: wrap;\n    list-style: none;\n    margin: 0;\n    padding: 0;\n    opacity: 0;\n    visibility: hidden;\n    transition: all .3s linear;\n}\n\n.rt-accordion-menu__items * {\n    display: none;\n}\n\n.rt-accordion-menu__items--visible {\n    opacity: 1;\n    visibility: visible;\n}\n\n.rt-accordion-menu__items--visible * {\n    display: initial;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
